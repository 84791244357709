import type { FC } from 'react';
import { css } from '@styled-system/css';
import { Box, VStack } from '@styled-system/jsx';

export const ContinueReadingSeriesItemSkeleton: FC = () => {
  return (
    <VStack
      minWidth="130px"
      gap="8px"
      alignItems="flex-start"
      opacity="0.4"
      boxShadow="0 2px 3px 0 rgba(0, 0, 0, .14), 0 2px 2px -1px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12)"
    >
      <Box
        className={skeletonCss}
        height="88px"
        minWidth="130px"
        borderBottomLeftRadius="0"
        borderBottomRightRadius="0"
      />
      <Box
        className={skeletonCss}
        height="20px"
        minWidth="110px"
        margin="8px auto"
      />
    </VStack>
  );
};

const skeletonCss = css({
  borderRadius: '4px',
  backgroundColor: '#e2e5e7',
  backgroundImage:
    'linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%)',
  backgroundSize: '800px 100px',
  animation: 'waveLines 2s infinite ease-out',
});
