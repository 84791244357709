import type { FC } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import noThumbnailWrapperImage from '~/common/assets/images/story-series-no-thumbnail.png';
import type { SeriesOverview } from '~/common/types/series-overview';
import type { StoryOverview } from '~/common/types/story-overview';
import { resizeServingUrl } from '~/utils/image';
import { routes } from '~/utils/routes';
import { Link } from '@app/_components/link';
import { Badge } from '@app/_components/series-list-item/badge';
import { NovelBadge } from '@app/_components/series-list-item/novel-badge';
import { css } from '@styled-system/css';
import { HStack, Box, VStack, Paragraph } from '@styled-system/jsx';

type Props = {
  story: StoryOverview;
  onClickOptions: (se: SeriesOverview) => void;
  showSensitive: boolean;
};

export const ContinueReadingSeriesItem: FC<Props> = ({
  story,
  onClickOptions,
  showSensitive,
}) => {
  if (!story.series) return null;

  const link =
    story.series?.searchableStoryCount === 1
      ? routes.story.url(story.id)
      : routes.series.url(story.series.id);

  return (
    <VStack
      overflow="hidden"
      cursor="pointer"
      color="accent.700"
      position="relative"
      minWidth="132px"
      w="132px"
      px="0 16px"
      gap="0"
      alignItems="flex-start"
      className={css({
        '& a:hover': {
          opacity: '0.8',
        },
      })}
    >
      <Link href={link}>
        <Box
          w="132px"
          h="88px"
          overflow="hidden"
          borderRadius="3px"
          position="relative"
        >
          <img
            src={
              story.series.thumbnailURL
                ? resizeServingUrl(
                    story.series.thumbnailURL,
                    350,
                    false,
                    !story.series.hasSensitiveStories || showSensitive
                      ? undefined
                      : 'default'
                  ) ?? noThumbnailWrapperImage
                : noThumbnailWrapperImage
            }
            alt={story.series.title}
            loading="lazy"
            className={css({
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '3px',
            })}
          />
          {/* Novel Badge */}
          {story.series.isNovel && <NovelBadge />}
          {/* Senstive Badge or Complete Badge  */}
          {story.series.hasSensitiveStories ? (
            <Badge label="センシティブ" width={'auto'} />
          ) : (
            <>
              {story.series.isOneshot ? (
                <Badge label="読切" />
              ) : story.series.isCompleted ? (
                <Badge label="完結" />
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      </Link>
      <HStack
        cursor="pointer"
        onClick={() => story.series && onClickOptions(story.series)}
        marginTop="4px"
        width="132px"
        maxWidth="132px"
        justifyContent="space-between"
        gap="0"
      >
        <Paragraph
          fontSize="size_12"
          lineClamp="1"
          maxLines="1"
          maxWidth="120px"
        >{`${story.seriesIndex || 1}話　${story.series.title}`}</Paragraph>
        <Box minWidth="14px">
          <BsThreeDots />
        </Box>
      </HStack>
    </VStack>
  );
};
