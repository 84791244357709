import { Center, type CenterProps } from '@styled-system/jsx';
import loadingIcon from './assets/loading-icon.svg';

type Props = {
  small?: boolean;
} & CenterProps;

export const Loading = ({ small, ...props }: Props) => (
  <Center padding="100px" textAlign="center" {...props}>
    <img
      loading="lazy"
      src={loadingIcon}
      alt="loading"
      width={small ? 25 : 50}
      height={small ? 25 : 50}
    />
  </Center>
);
