import type { ParsedUrlQuery } from 'node:querystring';
import qs from 'query-string';
import { getFirstQuery } from './url';

// in Server Side Rendering
export const isServer = () => typeof window === 'undefined';

export const hasRedirectUnnecessaryQueryParams = (
  query: ParsedUrlQuery
): boolean => {
  return (
    typeof query['bot'] !== 'undefined' ||
    typeof query['ua'] !== 'undefined' ||
    query['page'] === '0'
  );
};

export const stripUnnecessaryQueryParams = (
  url: string,
  query: ParsedUrlQuery
): string => {
  const newQuery = {
    ...query,
  };
  delete newQuery['bot'];
  delete newQuery['ua'];

  // `/tag/` のときにtagNameを削除する
  if (url.startsWith('/tag/')) {
    delete newQuery['tagName'];
    // `official_typeを削除する
    delete newQuery['official_type'];
    // storyを削除する
    delete newQuery['story'];
  }

  // `/s/` のときにstoryIdを削除する
  if (url.startsWith('/s/')) {
    delete newQuery['storyId'];
  }

  // `/se/` のときにseriesIdを削除する
  if (url.startsWith('/se/')) {
    delete newQuery['seriesId'];
  }

  const page = getFirstQuery(query['page']);
  if (page === '0') {
    delete newQuery['page'];
  }

  const newUrl = qs.stringifyUrl(
    {
      url: qs.parseUrl(url).url,
      query: newQuery,
    },
    {
      skipNull: true,
    }
  );

  return newUrl;
};

const isEmptyParam = (query: ParsedUrlQuery, key: string): boolean =>
  typeof query[key] !== 'undefined' && query[key] === '';

// Remove empty ?official_type= or ?story= from tag url
// Examples: /tag/ホラー?official_type=
//           /tag/ホラー?story=
// If url is ok, return undefined
// If url is not ok, return the new url for apply a 301 redirection
export const redirectTagPageIfEmptyParametersUrl = (
  url: string,
  query: ParsedUrlQuery
): string | undefined => {
  if (isEmptyParam(query, 'official_type') || isEmptyParam(query, 'story')) {
    const newQuery = {
      ...query,
    };
    delete newQuery['official_type'];
    delete newQuery['story'];
    delete newQuery['tagName'];

    const newUrl = qs.stringifyUrl(
      {
        url: qs.parseUrl(url).url,
        query: newQuery,
      },
      {
        skipNull: true,
      }
    );
    return newUrl;
  } else {
    return;
  }
};
