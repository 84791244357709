import type { FC } from 'react';
import { Likes } from '~/app/_components/likes';
import { UsernameWithBadges } from '~/app/_components/username-with-badge/username-with-badge';
import noThumbnailWrapperImage from '~/common/assets/images/story-series-no-thumbnail.png';
import type { SeriesOverview } from '~/common/types/series-overview';
import { resizeServingUrl } from '~/utils/image';
import { routes } from '~/utils/routes';
import { Link } from '@app/_components/link';
import { css } from '@styled-system/css';
import {
  AspectRatio,
  Box,
  HStack,
  Paragraph,
  VStack,
  styled,
} from '@styled-system/jsx';

type AnalyticsParams = {
  sectionTitle: string;
};

type Props = {
  index: number;
  series: SeriesOverview;
  showSensitive: boolean;
  analyticsParams?: AnalyticsParams;
};

export const BigFeaturedSeriesItem: FC<Props> = ({
  index,
  series,
  showSensitive,
  analyticsParams,
}) => {
  const link = routes.series.url(series?.id);
  return (
    <Box
      position="relative"
      data-gtm-event-imp="feed_item_imp"
      data-gtm-event-click="feed_item_tapped"
      data-gtm-param-series-id={series.id}
      data-gtm-param-state={index}
      data-gtm-param-recommender-model-id={series.recommenderModelId}
      data-gtm-param-section-title={analyticsParams?.sectionTitle}
    >
      <VStack
        overflow="hidden"
        cursor="pointer"
        color="accent.700"
        alignItems="flex-start"
        position="relative"
        w="100%"
        height={{ base: '213px', lg: '270px' }}
        aspectRatio={{ base: 165 / 213, lg: 233 / 270 }}
        _hover={{
          '& > *': {
            opacity: '0.8',
          },
        }}
        gap={{
          base: '8px',
          lg: '10px',
        }}
      >
        <AspectRatio ratio={{ base: 165 / 110, lg: 233 / 155 }} w="100%">
          <Link href={link} className={css({ marginTop: '-2px' })}>
            <Box overflow="hidden" position="relative" width="100%">
              <styled.img
                src={
                  series.thumbnailURL
                    ? resizeServingUrl(
                        series.thumbnailURL,
                        233,
                        false,
                        !series.hasSensitiveStories || showSensitive
                          ? undefined
                          : 'default'
                      ) ?? noThumbnailWrapperImage
                    : noThumbnailWrapperImage
                }
                alt={series.title}
                loading="lazy"
                aspectRatio={{ base: 165 / 110, lg: 233 / 155 }}
                className={css({
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '8px',
                })}
              />
            </Box>
          </Link>
        </AspectRatio>
        {/* Bottom */}
        <VStack
          overflow="hidden"
          gap={{
            base: '4px',
            lg: '4px',
          }}
          w="100%"
          h="100%"
          justifyContent="space-between"
          position="relative"
        >
          <Link
            href={link}
            className={css({
              w: '100%',
            })}
          >
            <VStack
              gap={{
                base: '4px',
                lg: '4px',
              }}
              w="100%"
              h="100%"
              alignItems="flex-start"
              flex="1"
            >
              <Paragraph
                fontSize="size_14"
                fontWeight="bold"
                color="accent.1000"
                lineClamp="2"
                lineHeight={{ base: '16px', lg: '20px' }}
                height={{ base: '32px', lg: '40px' }}
              >
                {series.title}
              </Paragraph>
            </VStack>
          </Link>
          <HStack
            w="100%"
            display="flex"
            flexDir="row"
            WebkitOverflowScrolling="touch"
            alignItems="center"
            overflowX="auto"
            overflowY="hidden"
            flexWrap="nowrap"
            gap="8px"
            scrollbar={{ base: 'hidden', lg: 'auto' }}
            minHeight="17px"
          >
            {series.tags.slice(0, 6).map((tag) => (
              <styled.a
                backgroundColor="strong_bg"
                padding="2px 5px"
                borderRadius="4px"
                key={tag}
                flex="0 0 auto"
                href={`/tag/${encodeURIComponent(tag || '')}`}
              >
                <Paragraph
                  fontSize="size_11"
                  color="tint"
                  fontWeight={300}
                  maxLines="1"
                >
                  #{tag}
                </Paragraph>
              </styled.a>
            ))}
          </HStack>
          <HStack
            justifyContent="space-between"
            w="100%"
            alignItems="flex-start"
            flexShrink="0"
            marginTop="4px"
          >
            {/* User */}
            {series.user && (
              <UsernameWithBadges
                userId={series.user.id}
                username={series.user.name}
                isOfficialWriter={series.user.isOfficialWriter}
                isVip={series.user.isVip}
                className={css({
                  pb: {
                    base: '8px',
                    lg: '16px',
                  },
                  flex: 1,
                })}
              />
            )}
            <Link
              href={link}
              className={css({
                pr: {
                  base: '8px',
                  lg: '16px',
                },
                pb: {
                  base: '8px',
                  lg: '16px',
                },
                flexShrink: 0,
              })}
            >
              <Likes likesCount={series.likeCount} variants="big" />
            </Link>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};
