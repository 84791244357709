import type { User } from 'firebase/auth';
import { signIn, type SignInResponse } from 'next-auth/react';

export const signInNextAuthFromFirebaseUser = (
  firebaseUser: User,
  fetchTellerUser?: boolean,
  redirectTo?: string
): Promise<SignInResponse | undefined> => {
  return new Promise(async (resolve, reject) => {
    const idToken = await firebaseUser.getIdToken(true);

    const avoidRedirect = (() => {
      if (firebaseUser.isAnonymous) return true;

      if (firebaseUser.metadata.creationTime) {
        const createdAt = new Date(firebaseUser.metadata.creationTime);
        const now = new Date();

        const differenceInSeconds =
          (now.getTime() - createdAt.getTime()) / 1000;
        // If the user has been registered in the last 5 minutes, avoid redirect
        // that will be handled in finish registration page
        if (differenceInSeconds < 60 * 5) {
          return true;
        }
      }
      return false;
    })();

    const result = await signIn('id-token', {
      idToken,
      fetchTellerUser,
      redirect: !avoidRedirect,
      callbackUrl: avoidRedirect ? undefined : redirectTo ?? '/',
    });

    if (result?.ok) {
      resolve(result);
    } else {
      reject(
        result?.error ?? new Error('Failed to sign in with Firebase user')
      );
    }
  });
};
