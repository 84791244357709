import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { useFirebaseAnalytics } from '~/common/hooks/use-firebase-analytics';
import { NOVEL_STUDIO_URL } from '~/utils/constants';
import { routes } from '~/utils/routes';

export const useLocalHooks = () => {
  const router = useRouter();

  const { session } = useTellerSession();
  const isAnonymous = session?.isAnonymous;
  const { logEvent } = useFirebaseAnalytics();

  // handle on click write story
  const handleStoryWriteClick = useCallback(async () => {
    if (isAnonymous === true) {
      router.push(routes.login.url(), undefined);
    } else {
      if (typeof window !== 'undefined') {
        logEvent('home_banner_click_write_story');
        window.location.href = `${NOVEL_STUDIO_URL}?webUid=${session?.uid}`;
      }
    }
  }, [isAnonymous, router, logEvent, session?.uid]);

  return {
    handleStoryWriteClick,
  };
};
