import { LuArrowRightCircle } from 'react-icons/lu';
import { routes } from '~/utils/routes';
import { ButtonNextLink } from '@app/_components/button';
import { Link } from '@app/_components/link';
import { css } from '@styled-system/css';
import { Image, VStack, Paragraph } from '@styled-system/jsx';
import forBeginnersTitle from './assets/for_beginners_title.svg';

export const ForBeginnersBanner = () => (
  <VStack
    borderRadius="8px"
    width={{ base: '100%', lg: '306px' }}
    maxWidth={{ base: '400px', lg: '306px' }}
    height={{ base: 'auto', lg: '424px' }}
    backgroundColor="cute_green"
    backgroundPosition="center"
    backgroundSize="cover"
    padding="16px"
    gap="16px"
  >
    <Image
      marginTop={{ base: '0', lg: '8px' }}
      src={forBeginnersTitle}
      alt="はじめての方へ"
      marginRight="auto"
    />

    <Paragraph
      fontSize={{ base: 'size_14', lg: 'size_16' }}
      lineHeight="26px"
      color="white"
    >
      みんなでルールを守り、すべての方がテラーノベルを楽しめるように心がけましょう。
    </Paragraph>

    <VStack
      width="100%"
      backgroundColor="white"
      color="cute_green"
      fontSize="size_14"
      fontWeight="700"
      borderRadius="12px"
      padding="8px 8px 0 16px"
    >
      <Link href={routes.handbook.action.url()} className={OptionStyle}>
        楽しむためのアクション
        <LuArrowRightCircle size="23px" />
      </Link>
      <Link
        href={routes.handbook['keep-in-mind'].url()}
        className={OptionStyle}
      >
        楽しむための心がけ
        <LuArrowRightCircle size="23px" />
      </Link>
      <Link
        href={routes.handbook['inappropiate-behavior'].url()}
        className={OptionStyle}
      >
        不適切な行動を見つけた時の対処法
        <LuArrowRightCircle size="23px" />
      </Link>
    </VStack>

    <ButtonNextLink
      size="small"
      width="100%"
      height="50px"
      marginTop={{ base: '0', lg: '6px' }}
      backgroundColor="dark_green"
      color="white"
      fontSize={{ base: 'size_16', lg: 'size_14' }}
      border="2px solid white"
      maxWidth={{ base: '100%', lg: '258px' }}
      href={routes.handbook.url()}
      _focus={{
        outline: 0,
      }}
    >
      ハンドブックをみる
    </ButtonNextLink>
  </VStack>
);

const OptionStyle = css({
  display: 'flex',
  width: '100%',
  paddingBottom: '8px',
  height: '47px',
  paddingTop: '8px',
  justifyContent: 'space-between',
  borderBottom: '1px solid token(colors.separator)',
  _lastOfType: {
    borderBottom: 'none',
    paddingBottom: '0',
  },
  _hover: {
    opacity: 0.7,
  },
});
