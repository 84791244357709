'use client';
import { CommunityGuidelinesBanner } from '@app/(default-layout)/_home/_components/community-guidelines-banner';
import { ForBeginnersBanner } from '@app/(default-layout)/_home/_components/for-beginners-banner';
import { LetsWriteBanner } from '@app/(default-layout)/_home/_components/lets-write-banner';
import { SafetyInformationBanner } from '@app/(default-layout)/_home/_components/safety-information-banner';
import { VStack, Flex } from '@styled-system/jsx';
import { useLocalHooks } from './hooks/use-local-hooks';

export const BannersContainer = () => {
  const { handleStoryWriteClick } = useLocalHooks();
  return (
    <Flex
      direction={{ base: 'column', lg: 'row' }}
      width="100%"
      backgroundColor={{ base: 'surface_secondary', lg: 'transparent' }}
      padding={{ base: '24px 16px', lg: 0 }}
      margin={{ base: 'auto', lg: '0 auto 48px auto' }}
      justifyContent="space-between"
      alignItems="center"
      gap={{ base: '16px', lg: '24px' }}
    >
      <LetsWriteBanner onClickWrite={handleStoryWriteClick} />
      <ForBeginnersBanner />
      <VStack
        backgroundColor="surface_secondary"
        padding={{ base: '0', lg: '20px' }}
        borderRadius="8px"
        height={{ base: 'auto', lg: '424px' }}
        gap="16px"
      >
        <CommunityGuidelinesBanner />
        <SafetyInformationBanner />
      </VStack>
    </Flex>
  );
};
