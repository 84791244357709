import { ImageCategory } from '~/models/image';

export const SERVING_URL_DOMAIN_REGEXP =
  /^https?:\/\/lh\d+.googleusercontent.com/;

/**
 * ServingURLのドメインであるか
 * @param url
 * @returns
 */
export const isServingUrl = (url: string) =>
  url.match(SERVING_URL_DOMAIN_REGEXP);

export const resizeServingUrl = (
  url: string | null,
  pxWidth: number,
  isCrop: Boolean = false,
  blur?: 'max' | 'default'
) => {
  // urlがnullまたは `=` など既にクエリパラメータが入っているときは指定しない
  if (url === null || !isServingUrl(url) || /=/.test(url)) return url;
  const blurParam = (() => {
    if (!blur) return '';
    if (blur === 'max') return '-fSoften=1,200,0';
    return '-fSoften=1,150,0';
  })();

  return `${url}=s${pxWidth}${isCrop ? '-p' : ''}-rw${blurParam}`;
};

export const resizedSceneBackgroundUrl = (
  sceneBackgroundUrl: string,
  category: ImageCategory | null
): string => {
  if (typeof window === 'undefined' || category === null) {
    return `${sceneBackgroundUrl}=s200`;
  }

  switch (category) {
    case ImageCategory.CHAT_BACKGROUND:
    case ImageCategory.TALK_BACKGROUND:
      const screenWidth = window.innerWidth;
      let widthParam = 1000;

      if (screenWidth > 600 && screenWidth <= 800) {
        widthParam = 2000;
      } else if (screenWidth > 800 && screenWidth <= 1000) {
        widthParam = 2500;
      } else if (screenWidth > 1000) {
        widthParam = 3000;
      }

      return resizeServingUrl(sceneBackgroundUrl, widthParam)!;
    case ImageCategory.CONTENT:
      return resizeServingUrl(sceneBackgroundUrl, 600)!;
    case ImageCategory.PUSH_NOTIFICATION_ICON:
      return resizeServingUrl(sceneBackgroundUrl, 120)!;
    case ImageCategory.STAMP:
      return resizeServingUrl(sceneBackgroundUrl, 400)!;
    case ImageCategory.STORY_SERIES_THUMBNAIL:
      return resizeServingUrl(sceneBackgroundUrl, 300)!;
    case ImageCategory.CHARACTER_ICON:
      return resizeServingUrl(sceneBackgroundUrl, 100)!;
    case ImageCategory.USER_PROFILE:
      return resizeServingUrl(sceneBackgroundUrl, 100)!;
  }
};
