import type { FC } from 'react';
import { useRef } from 'react';
import { PiDotsThreeOutlineFill } from 'react-icons/pi';
import {
  Actionsheet,
  actionsheetOptionStyle,
  actionsheetOptionLinkStyle,
} from '@app/_components/actionsheet';
import { css } from '@styled-system/css';
import { Center, Divider, VStack } from '@styled-system/jsx';
import lineImage from './assets/line-circle.svg';
import linkImage from './assets/link-circle.svg';
import xShareImage from './assets/share-x.svg';
import { useLocalHooks } from './use-local-hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  text: string;
  url: string;
  hashtags?: ReadonlyArray<string>;
  onShare?: () => void;
}

export const ShareActionsheet: FC<Props> = ({
  isOpen,
  onClose,
  text,
  url,
  hashtags,
  onShare,
}) => {
  const urlInputRef = useRef<HTMLInputElement>(null);

  const { encodedText, encodedUrl, encodedTags, copyLink, webShare } =
    useLocalHooks({
      text,
      url,
      hashtags,
      urlInputRef,
      onShare,
      onClose,
    });

  return (
    <Actionsheet open={isOpen || false} onClose={onClose}>
      <VStack
        position="relative"
        zIndex="share_popover"
        pb="calc(env(safe-area-inset-bottom) + 16px)"
      >
        <input
          type="text"
          value={url}
          readOnly
          ref={urlInputRef}
          className={css({
            display: 'block',
            position: 'absolute',
            top: '-2147483647px',
            height: '10px',
            width: '100px',
            border: 'none',
            background: 'transparent',
            outline: 'none',
            boxSizing: 'border-box',
            opacity: 0,
          })}
        />

        <div className={css({ minHeight: '150px', minWidth: '100%' })}>
          <ul
            className={css({
              marginTop: '10px',
              paddingLeft: '8px',
              paddingRight: '8px',
              fontSize: 'size_14',
              fontWeight: '700',
            })}
          >
            <li className={actionsheetOptionStyle}>
              <a
                href={`https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}&hashtags=${encodedTags}`}
                target="_blank"
                className={actionsheetOptionLinkStyle}
              >
                <img src={xShareImage} width="32" height="32" alt="Xでシェア" />
                <span>ポスト</span>
              </a>
            </li>
            <li className={actionsheetOptionStyle}>
              <a
                href={`https://timeline.line.me/social-plugin/share?url=${encodedUrl}`}
                target="_blank"
                className={actionsheetOptionLinkStyle}
              >
                <img src={lineImage} alt="LINEで共有" />
                LINEで共有
              </a>
            </li>
            <li className={actionsheetOptionStyle}>
              <a onClick={copyLink} className={actionsheetOptionLinkStyle}>
                <img
                  src={linkImage}
                  width="32"
                  height="32"
                  alt="リンクをコピー"
                />
                リンクをコピー
              </a>
            </li>
            <VStack hideFrom="lg" gap="0">
              <Divider />
              <li className={actionsheetOptionStyle}>
                <a onClick={webShare} className={actionsheetOptionLinkStyle}>
                  <Center w="32px" h="32px" bg="#EDEEEF" borderRadius="16px">
                    <PiDotsThreeOutlineFill size="20px" color="#989BA1" />
                  </Center>
                  その他
                </a>
              </li>
            </VStack>
          </ul>
        </div>
      </VStack>
    </Actionsheet>
  );
};
