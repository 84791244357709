'use client';
import type { PropsWithChildren } from 'react';
import { css, cva } from '@styled-system/css';
import { HStack, Flex, HstackProps } from '@styled-system/jsx';
import bigArrow from './assets/arrow-big.png';
import { useLocalHooks } from './hooks/use-local-hooks';

type Props = PropsWithChildren<{
  carouselStyle: 'smallCell' | 'bigCell' | 'portraitImages';
}> &
  HstackProps;

export const HorizontalArrowsCarousel = ({
  carouselStyle,
  children,
  ...hstackProps
}: Props) => {
  const { showRightArrow, showLeftArrow, scroll, seriesRef } = useLocalHooks();

  return (
    <Flex className={wrapperStyles}>
      {/* Left button  */}
      {showLeftArrow && (
        <button
          onClick={() => scroll(false)}
          className={scrollButtonStyles({
            position: 'previousPage',
            carouselStyle,
          })}
        >
          <img
            className={css({
              width: '10px',
              cursor: 'pointer',
              transform: 'rotate(180deg)',
              verticalAlign: 'middle',
              marginRight: '35px',
              display: 'inline-block',
              _hover: {
                opacity: '0.8',
              },
            })}
            src={bigArrow}
            alt="前のページ"
          />
        </button>
      )}
      <HStack
        {...hstackProps}
        overflowX="auto"
        scrollbar="hidden"
        ref={seriesRef}
      >
        {children}
      </HStack>
      {showRightArrow && (
        <button
          onClick={() => scroll(true)}
          className={scrollButtonStyles({
            position: 'nextPage',
            carouselStyle,
          })}
        >
          <img
            className={css({
              maxWidth: '100%',
              verticalAlign: 'middle',
              marginLeft: '35px',
              width: '10px',
              height: 'auto',
              cursor: 'pointer',
              display: 'inline-block',
              _hover: {
                opacity: '0.8',
              },
            })}
            src={bigArrow}
            alt="前のページ"
          />
        </button>
      )}
    </Flex>
  );
};

const wrapperStyles = css({
  position: 'relative',
  width: '100%',
  _hover: {
    '& button': {
      opacity: '1',
    },
  },
});

const scrollButtonStyles = cva({
  base: {
    display: {
      base: 'none',
      lg: 'block',
    },
    position: 'absolute',
    outline: 'none',
    cursor: 'pointer',
    zIndex: '9',
    opacity: '0',
    transition: 'opacity 0.2s ease-out',
    _hover: {
      '& img': {
        opacity: '0.7',
      },
    },
  },
  variants: {
    position: {
      previousPage: {
        backgroundImage:
          'linear-gradient(-90deg, #fff 0%, rgba(250, 250, 250, 0) 0.01%, #fafafa 38.02%)',
        left: '-30px',
      },
      nextPage: {
        backgroundImage:
          'linear-gradient(90deg, #fff 0%, rgba(250, 250, 250, 0) 0.01%, #fafafa 38.02%)',
        right: '-30px',
      },
    },
    carouselStyle: {
      smallCell: {
        height: '88px',
        width: '60px',
      },
      bigCell: {
        height: '180px',
        width: '100px',
      },
      portraitImages: {
        height: '100%',
        width: '60px',
      },
    },
  },
});
