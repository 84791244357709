'use client';
import Script from 'next/script';
import React from 'react';
import { useGoogleOneTapLogin } from './use-google-onetap-login';

export const GoogleOneTapLoginProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  useGoogleOneTapLogin();

  return (
    <>
      {/* Google Auth */}
      <Script
        id="google-auth-script"
        src="https://accounts.google.com/gsi/client"
        async
        defer
      />
      {children}
    </>
  );
};
