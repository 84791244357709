import type { SnakeCasedPropertiesDeep } from 'type-fest';
import { fetchForClient } from '~/infra/api/fetch-api';
import type {
  mutation_inspection,
  mutation_profiling,
  mutation_follow_user,
  mutation_reading_progress,
} from '~/infra/api/rpc';

type ReportStoryViolationRequest = Required<
  SnakeCasedPropertiesDeep<mutation_inspection.IReportStoryViolationRequest>
>;

export type UpdateUserPreferenceSensitiveRequest =
  SnakeCasedPropertiesDeep<mutation_profiling.IUpdateUserPreferenceSensitiveRequest>;

const SERVICE_PATH = '/mutation.MutationService';

export const reportStoryViolation = async (
  req: ReportStoryViolationRequest
): Promise<void> => {
  return await fetchForClient({
    path: `${SERVICE_PATH}/ReportStoryViolation`,
    options: {
      body: JSON.stringify(req),
      method: 'POST',
    },
  });
};

export const updateUserPreferenceSensitive = async (
  req: UpdateUserPreferenceSensitiveRequest
): Promise<void> => {
  return await fetchForClient({
    path: `${SERVICE_PATH}/UpdateUserPreferenceSensitive`,
    options: {
      body: JSON.stringify(req),
      method: 'POST',
    },
  });
};

export const followUser = async (
  userId: string,
  mutualFollowSeriesId?: string
): Promise<void> => {
  const req: mutation_follow_user.IFollowUserRequest = {
    userId,
    mutualFollowSeriesId,
  };
  await fetchForClient({
    path: `${SERVICE_PATH}/FollowUser`,
    options: {
      body: JSON.stringify(req),
      method: 'POST',
    },
  });
};

export const unfollowUser = async (userId: string): Promise<void> => {
  const req: mutation_follow_user.IUnfollowUserRequest = {
    userId,
  };
  await fetchForClient({
    path: `${SERVICE_PATH}/UnfollowUser`,
    options: {
      body: JSON.stringify(req),
      method: 'POST',
    },
  });
};

export const deleteSeriesReadingProgress = async (
  seriesId: string
): Promise<void> => {
  const req: mutation_reading_progress.IDeleteSeriesReadingProgressRequest = {
    id: seriesId,
  };
  await fetchForClient({
    path: `${SERVICE_PATH}/DeleteSeriesReadingProgress`,
    options: {
      body: JSON.stringify(req),
      method: 'POST',
    },
  });
};
