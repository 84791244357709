import type { FC } from 'react';
import { css } from '@styled-system/css';
import { Box, HStack, VStack } from '@styled-system/jsx';

export const BigFeaturedSeriesItemSkeleton: FC = () => {
  return (
    <VStack
      color="accent.700"
      alignItems="flex-start"
      position="relative"
      w="100%"
      opacity="0.4"
      height={{ base: '213px', lg: '250px' }}
      aspectRatio={{ base: 165 / 213, lg: 233 / 250 }}
      backgroundColor="white"
      boxShadow="0 2px 3px 0 rgba(0, 0, 0, .14), 0 2px 2px -1px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12)"
      borderRadius="10px"
      gap={{
        base: '8px',
        lg: '10px',
      }}
    >
      <Box
        borderTopLeftRadius="10px"
        borderTopRightRadius="10px"
        className={skeletonCss}
        width="100%"
        minHeight={{ base: '110px', lg: '135px' }}
        minWidth={{ base: '165px', lg: '233px' }}
        borderBottomLeftRadius="0"
        borderBottomRightRadius="0"
      />
      <VStack
        gap={{
          base: '4px',
          lg: '4px',
        }}
        w="100%"
        h="100%"
        justifyContent="space-between"
        position="relative"
      >
        <VStack
          gap={{
            base: '16px',
            lg: '16px',
          }}
          w="100%"
          h="100%"
          alignItems="flex-start"
          flex="1"
          padding={{ base: '8px', lg: '8px 16px' }}
        >
          <Box className={skeletonCss} height="40px" width="100%" />
          <HStack width="100%" justifyContent="space-between">
            <Box width="60%" height="20px" className={skeletonCss} />
            <Box width="15%" height="20px" className={skeletonCss} />
          </HStack>
        </VStack>
      </VStack>
    </VStack>
  );
};

const skeletonCss = css({
  borderRadius: '4px',
  backgroundColor: '#e2e5e7',
  backgroundImage:
    'linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%)',
  backgroundSize: '800px 100px',
  animation: 'waveLines 2s infinite ease-out',
});
