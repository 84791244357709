import { convertIStoryResponseToStoryOverview } from '~/common/converter/story/story';
import type { StoryResponse } from '~/repositories/query-repository';
import { notUndefinedOrNull } from '~/utils/styles/type';
import { logError } from '~/utils/utils';
import { buildSeries } from '@app/_repositories/query-builder';
import { getQueriesForClient } from '@app/_repositories/query-service';

const STORIES_LIMIT = 20;

export const fetchStoriesReadingProgressForHome = async ({
  limit,
}: {
  limit?: number;
}) => {
  try {
    const requestId = `FETCH_SERIES_READING_PROGRESS`;

    const response = await getQueriesForClient({
      request_list: [
        {
          request_id: requestId,
          me_request: {
            require_user: {
              require_series_reading_progress_page: {
                require_reading_progress_list: {
                  require_story_reading_progress: {
                    require_story: {
                      require_id: true,
                      require_series_index: true,
                      require_is_admin_sensitive: true,
                      require_is_user_sensitive: true,
                      require_series: {
                        ...buildSeries(),
                        require_searchable_story_count: true,
                        require_has_novel_script_story: true,
                        require_total_like_count: undefined,
                        require_description: undefined,
                        require_updated_at: undefined,
                        require_latest_published_at: undefined,
                        require_tags: undefined,
                        require_is_official: undefined,
                        require_is_completed: true,
                        require_has_vip_only_story: undefined,
                        require_has_free_story: undefined,
                        require_user_availability: undefined,
                        require_shared_with_status: undefined,
                        require_is_sensitive: true,
                        require_can_show_google_ad: undefined,
                        require_user: undefined,
                      },
                    },
                  },
                },
                cursor: {
                  limit: limit || STORIES_LIMIT,
                },
              },
            },
          },
        },
      ],
    });

    const storiesPage =
      response?.response_list[0]?.me?.user?.series_reading_progress_page?.reading_progress_list?.map(
        (st) => st.story_reading_progress?.story
      );

    if (!storiesPage) return [];

    return (storiesPage?.filter(notUndefinedOrNull) ?? []).map((s) =>
      convertIStoryResponseToStoryOverview(s as StoryResponse)
    );
  } catch (error: any) {
    logError(error);
    return [];
  }
};
