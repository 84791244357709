import { useRef, useMemo, useCallback, useEffect, useState } from 'react';
import { useScroll } from 'react-use';

export const useLocalHooks = () => {
  const seriesRef = useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState(false);

  const { x } = useScroll(seriesRef);

  useEffect(() => {
    if (!seriesRef.current) return;
    setHasScroll(
      seriesRef?.current?.scrollWidth > seriesRef?.current?.clientWidth
    );
  }, []);

  const showRightArrow = useMemo(() => {
    if (!hasScroll || !seriesRef.current) return false;
    return (
      x !== seriesRef?.current?.scrollWidth - seriesRef?.current?.clientWidth
    );
  }, [hasScroll, x]);

  const showLeftArrow = useMemo(() => {
    return x > 0;
  }, [x]);

  const scroll = useCallback((right: boolean) => {
    if (seriesRef.current) {
      const pos = seriesRef.current.clientWidth - 167;
      seriesRef.current.scrollBy({
        left: right ? pos : -pos,
        behavior: 'smooth',
      });
    }
  }, []);

  return {
    seriesRef,
    showRightArrow,
    showLeftArrow,
    scroll,
  };
};
