import { useMemo } from 'react';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { useClientOnlyMediaQuery } from '~/common/hooks/use-client-ony-media-query';
import { Media } from '~/utils/styles/enums';
import { useFetchMeRecommendedSeries } from './use-fetch-me-recommended-series';

const HOME_FEATURED_STORIES_PER_PAGE = 28;
const HOME_LARGE_ROW_ITEMS = 4;
const HOME_SP_ROW_ITEMS = 2;

export const useLocalHooks = () => {
  const { session } = useTellerSession();

  const {
    seriesList: meRecommendedSeries,
    hasNextPage: hasMoreMeRecommendedSeries,
    isLoading: isLoadingMeRecommendedSeries,
    loadNextPage: loadNextMeRecommendedSeriesPage,
  } = useFetchMeRecommendedSeries(session?.uid, HOME_FEATURED_STORIES_PER_PAGE);

  const screenLarge = useClientOnlyMediaQuery(Media.LARGE);
  const lastRowElements = screenLarge
    ? HOME_LARGE_ROW_ITEMS
    : HOME_SP_ROW_ITEMS;
  const sliceIndex = -(meRecommendedSeries.length % lastRowElements);

  const lastRowCompletedSeries = useMemo(() => {
    if (sliceIndex === 0) return meRecommendedSeries;
    return meRecommendedSeries.slice(0, sliceIndex);
  }, [sliceIndex, meRecommendedSeries]);

  return {
    series: lastRowCompletedSeries,
    hasMoreMeRecommendedSeries,
    isLoadingMeRecommendedSeries,
    loadNextMeRecommendedSeriesPage,
    showSensitive: session?.tellerUser?.showSensitive || false,
  };
};
