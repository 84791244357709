export const PLATFORM = {
  MOBILE: 'mobile',
  PC: 'pc',
} as const;
export type PLATFORM = typeof PLATFORM[keyof typeof PLATFORM];

export const getPlatform = (userAgent: string): PLATFORM => {
  return /mobile|ip(hone|od|ad)|android|blackberry|iemobile|kindle|netfront|silk-accelerated|(hpw|web)os|fennec|minimo|opera m(obi|ini)|blazer|dolfin|dolphin|skyfire|zune/gi.test(
    userAgent
  )
    ? PLATFORM.MOBILE
    : PLATFORM.PC;
};

export const isBot = (userAgent: string) =>
  /bot|google|crawler|spider|robot|crawling/gi.test(userAgent);

export const isMobile = (platform: PLATFORM) => platform === PLATFORM.MOBILE;

export const isPC = (platform: PLATFORM) => platform === PLATFORM.PC;
