import { fetchForClient, fetchForServer } from '~/infra/api/fetch-api';
import type { QueryRequest, QueryResponse } from './query-types';

const SERVICE_PATH = '/query.QueryService';

export const getQueriesForServer = async (
  req: QueryRequest,
  option?: RequestInit
): Promise<QueryResponse> => {
  return fetchForServer({
    path: `${SERVICE_PATH}/Query`,
    options: {
      ...option,
      method: 'POST',
      body: JSON.stringify(req),
    },
  });
};

export const getQueriesForClient = async (
  req: QueryRequest,
  option?: RequestInit
): Promise<QueryResponse> => {
  return fetchForClient({
    path: `${SERVICE_PATH}/Query`,
    options: {
      ...option,
      method: 'POST',
      body: JSON.stringify(req),
    },
  });
};
