import { routes } from '~/utils/routes';
import { Link } from '@app/_components/link';
import { css } from '@styled-system/css';
import { Image, VStack, HStack, Paragraph } from '@styled-system/jsx';
import safetyInformationIcon from './assets/safety_information_icon.svg';

export const SafetyInformationBanner = () => (
  <VStack
    borderRadius="8px"
    width={{ base: '100%', lg: '272px' }}
    maxWidth={{ base: '400px', lg: '272px' }}
    backgroundColor="white"
    backgroundPosition="center"
    backgroundSize="cover"
    padding="16px 16px 16px 16px"
    gap="12px"
    border={{ base: '1px solid token(colors.separator)', lg: 'none' }}
  >
    <HStack justifyContent="left" marginRight="auto" gap="0">
      <Image
        src={safetyInformationIcon}
        alt="安心安全への取り組み"
        marginRight="auto"
      />
      <Paragraph fontSize="size_16" fontWeight="700">
        安心安全への取り組み
      </Paragraph>
    </HStack>

    <Paragraph fontSize="size_14" lineHeight="21px">
      すべての方に安心安全にご利用いただける環境を目指し、健全な運営のための取り組みを実施しています。{' '}
    </Paragraph>
    <Link
      href={routes.safety.url()}
      className={css({
        color: 'tint',
        marginRight: 'auto',
        _hover: {
          opacity: 0.7,
        },
      })}
    >
      詳しくはこちら
    </Link>
  </VStack>
);
