'use client';
import { AnimatePresence, motion } from 'framer-motion';
import { BigFeaturedSeriesItem } from '@app/(default-layout)/_home/_components/big-featured-series-item';
import { BigFeaturedSeriesItemSkeleton } from '@app/(default-layout)/_home/_components/big-featured-series-item/big-featured-series-item-skeleton';
import { SectionTitle } from '@app/(default-layout)/_home/_components/section-title';
import { SpContentSeparator } from '@app/(default-layout)/_home/_components/sp-content-separator';
import { Loading } from '@app/_components/loading';
import { css } from '@styled-system/css';
import { VStack, Grid } from '@styled-system/jsx';
import { useLocalHooks } from './hooks';

export const MeRecommendedSeriesContainer = () => {
  const {
    series,
    hasMoreMeRecommendedSeries,
    isLoadingMeRecommendedSeries,
    loadNextMeRecommendedSeriesPage,
    showSensitive,
  } = useLocalHooks();

  const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <>
      <SpContentSeparator />
      <VStack
        marginTop={{ base: '8px', lg: '40px' }}
        width="100%"
        padding={{ base: '0 16px', lg: '0' }}
        marginBottom={{ base: '8px', lg: '40px' }}
      >
        <SectionTitle title="あなたにおすすめ" />

        <Grid
          marginTop="12px"
          gridTemplateColumns={{
            base: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
          gap="12px"
          width="100%"
          maxWidth={{ base: '385px', md: '550px', lg: 'none' }}
        >
          {series.length === 0 && (
            <>
              {skeletons.map((sk) => (
                <BigFeaturedSeriesItemSkeleton key={sk} />
              ))}
            </>
          )}
          <AnimatePresence initial={false}>
            {series.map((se, idx) => {
              if (!se) return null;
              return (
                <motion.div
                  key={`home-recommended-${se.id}`}
                  initial={{ opacity: 0, translateY: 10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    delay: 0.2,
                    duration: 0.3,
                    ease: 'easeOut',
                  }}
                >
                  <BigFeaturedSeriesItem
                    index={idx}
                    series={se}
                    showSensitive={showSensitive}
                    analyticsParams={{
                      sectionTitle: 'あなたにおすすめ',
                    }}
                  />
                </motion.div>
              );
            })}
          </AnimatePresence>
        </Grid>

        {(hasMoreMeRecommendedSeries === undefined ||
          hasMoreMeRecommendedSeries) && (
          <button
            className={css({
              display: 'block',
              maxWidth: '1096px',
              width: '100%',
              height: '60px',
              borderRadius: '8px',
              backgroundColor: 'highlight',
              fontSize: 'size_18',
              fontWeight: 600,
              lineHeight: '60px',
              marginTop: '20px',
              textAlign: 'center',
              _hover: {
                cursor: 'pointer',
                opacity: 0.7,
              },
            })}
            disabled={isLoadingMeRecommendedSeries}
            onClick={loadNextMeRecommendedSeriesPage}
          >
            {isLoadingMeRecommendedSeries ? (
              <Loading small p="0" />
            ) : (
              'もっと見る'
            )}
          </button>
        )}
      </VStack>
    </>
  );
};
