import { css } from '@styled-system/css';

export const NovelBadge = (): JSX.Element => (
  <span
    className={css({
      position: 'absolute',
      top: '0',
      left: '0',
      fontSize: 'size_11',
      fontWeight: 700,
      color: 'primary',
      background: 'rgba(102, 102, 102, 0.5)',
      borderRadius: '4px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '38px',
      height: '18px',
      lineHeight: '18px',
    })}
  >
    ノベル
  </span>
);
