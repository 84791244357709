import { AppError, ErrorType } from '~/models/app-error';
import { isServer } from '~/utils/ssr';
import { fetchClient, FetchParams } from './fetch';

const BASIC_CREDENTIALS = `${process.env['API_BASIC_USER']}:${process.env['API_BASIC_PASSWORD']}`;
const credentials = Buffer.from(BASIC_CREDENTIALS).toString('base64');

export const fetchForServer = async <T>({
  path,
  options,
}: FetchParams): Promise<T> => {
  if (!isServer()) {
    throw new AppError(
      ErrorType.UNKNOWN_ERROR,
      'This function is only available on the server side'
    );
  }
  const credentialHeaders = new Headers(options?.headers || {});
  credentialHeaders.set('Authorization', `Basic ${credentials}`);

  return fetchClient({
    path: path,
    options: {
      ...options,
      headers: credentialHeaders,
    },
  });
};
