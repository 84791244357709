import { cva } from '@styled-system/css';
import { HStack, HstackProps } from '@styled-system/jsx';
import heartImage from './assets/heart.svg';

type Props = {
  likesCount: number;
  alignRight?: boolean;
  variants?: 'normal' | 'big' | 'responsive';
} & HstackProps;

export const Likes: React.FC<Props> = ({
  likesCount,
  alignRight,
  variants,
  ...hstackProps
}) => {
  if (!likesCount) return null;

  return (
    <HStack
      animation="fadein 200ms ease-in"
      gap="3px"
      alignItems="center"
      userSelect="none"
      {...hstackProps}
    >
      <img src={heartImage} alt="♥" className={iconStyle({ size: variants })} />
      <p
        className={numberStyle({
          size: variants,
          alignment: alignRight ? 'right' : 'left',
        })}
      >
        {likesCount.toLocaleString()}
      </p>
    </HStack>
  );
};

const iconStyle = cva({
  base: {
    width: '10px',
    height: '10px',
  },
  variants: {
    size: {
      normal: {
        width: '10px',
        height: '10px',
      },
      big: {
        width: '16px',
        height: '15px',
      },
      responsive: {
        width: {
          base: '10px',
          lg: '16px',
        },
        height: {
          base: '10px',
          lg: '15px',
        },
      },
    },
  },
});

const numberStyle = cva({
  base: {
    fontSize: 'size_12',
    color: 'pink',
    fontWeight: 600,
  },
  variants: {
    size: {
      normal: {
        fontSize: 'size_12',
      },
      big: {
        fontSize: 'size_14',
      },
      responsive: {
        fontSize: {
          base: 'size_12',
          lg: 'size_14',
        },
      },
    },
    alignment: {
      left: {
        position: 'initial',
        bottom: 'initial',
        right: 'initial',
      },
      right: {
        position: 'absolute',
        bottom: '0',
        right: '0',
      },
    },
  },
});
