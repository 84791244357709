import type { MeRecommendedSeriesList } from '~/usecases/me-use-case';

const TOP_RECOMMENDED_SERIES_KEY = 'top-recommended-series';

type StoredSeriesByUser = {
  uid: string;
  addedAt: string;
  series: MeRecommendedSeriesList[];
};

const getStoredSeries = () => {
  if (!window) return;
  const storedSeries = sessionStorage.getItem(TOP_RECOMMENDED_SERIES_KEY);
  if (storedSeries) {
    return JSON.parse(storedSeries) as StoredSeriesByUser;
  }
  return;
};

const setStoredSeries = (storedSeriesByUser: StoredSeriesByUser) => {
  if (!window) return;
  const parsed = JSON.stringify(storedSeriesByUser);
  sessionStorage.setItem(TOP_RECOMMENDED_SERIES_KEY, parsed);
};

const validatedStoredSeries = (uid: string) => {
  const storedSeries = getStoredSeries();
  if (!storedSeries) {
    return;
  }

  // Clear stored data if uid is different (login by another user)
  if (storedSeries.uid !== uid) {
    clearStoredSeries();
    return;
  }

  const date = new Date(storedSeries.addedAt);
  const now = new Date();
  const moreThanOneHour = now.getTime() - date.getTime() > 60 * 60 * 1000;

  // Clear stored data if was created more than one hour ago
  if (moreThanOneHour) {
    clearStoredSeries();
    return;
  }

  return storedSeries;
};

export const clearStoredSeries = () => {
  if (!window) return;
  sessionStorage.removeItem(TOP_RECOMMENDED_SERIES_KEY);
};

export const getNumberOfStoredPagesFromSession = (uid: string) => {
  try {
    const storedSeries = validatedStoredSeries(uid);
    if (!storedSeries) return;
    return storedSeries.series.length;
  } catch {
    return 0;
  }
};

export const getStoredSeriesFromSession = (uid: string, page: number) => {
  if (!window) return;
  try {
    const storedSeries = validatedStoredSeries(uid);
    if (!storedSeries) return;
    if (storedSeries.series[page] !== undefined) {
      return storedSeries.series[page];
    }
    return;
  } catch {
    return;
  }
};

export const storeSeriesInSession = (
  uid: string,
  series: MeRecommendedSeriesList
) => {
  try {
    const storedSeries = getStoredSeries();

    if (storedSeries && storedSeries.uid !== uid) {
      clearStoredSeries();
    }
    if (!storedSeries) {
      setStoredSeries({
        uid,
        addedAt: new Date().toISOString(),
        series: [series],
      });
      return;
    } else {
      const storedDate = storedSeries.addedAt;
      setStoredSeries({
        uid,
        addedAt: storedDate,
        series: [...storedSeries.series, series],
      });
    }
  } catch {
    // no op
  }
};
