import type { CSSProperties, FC } from 'react';
import { Link } from '@app/_components/link';
import { css } from '@styled-system/css';
import { HStack, HstackProps, Paragraph } from '@styled-system/jsx';
import type { FontSizeToken } from '@styled-system/tokens';
import type { ConditionalValue } from '@styled-system/types';
import officialUserBadgeImage from './assets/official-user-badge.svg';
import vipUserBadgeImage from './assets/vip-badge.png';

type StyleConfigs = Partial<{
  userName: Partial<{
    fontSize: ConditionalValue<FontSizeToken>;
    fontWeight: string;
    color: string;
  }>;
}>;

export type Props = {
  userId: string;
  username: string | undefined;
  isOfficialWriter?: boolean;
  isVip?: boolean;
  disableLink?: boolean;
  styleConfigs?: StyleConfigs;
  className?: string | undefined;
  style?: CSSProperties;
} & HstackProps;

export const UsernameWithBadges: FC<Props> = ({
  userId,
  username,
  isOfficialWriter,
  isVip,
  disableLink,
  styleConfigs,
  className,
  style,
  ...hstackProps
}) => {
  return (
    <LinkableOuter userId={userId} disableLink={disableLink}>
      <HStack
        gap="4px"
        alignItems="center"
        className={className}
        style={style}
        {...hstackProps}
      >
        <Paragraph
          fontWeight={styleConfigs?.userName?.fontWeight ?? 'normal'}
          fontSize={styleConfigs?.userName?.fontSize ?? 'size_12'}
          color={styleConfigs?.userName?.color ?? 'accent.700'}
          maxLines="1"
          lineClamp="1"
        >
          {username || 'ゲスト'}
        </Paragraph>
        {isOfficialWriter && (
          <img
            alt="公式"
            loading="lazy"
            src={officialUserBadgeImage}
            className={css({
              w: '12px',
              h: '12px',
            })}
          />
        )}
        {!isOfficialWriter && isVip && (
          <img
            alt="VIP"
            loading="lazy"
            src={vipUserBadgeImage}
            className={css({
              w: '22px',
              h: '12px',
            })}
          />
        )}
      </HStack>
    </LinkableOuter>
  );
};

const LinkableOuter: FC<Pick<Props, 'userId' | 'disableLink'>> = ({
  userId,
  disableLink,
  children,
}) => {
  if (disableLink) {
    return <>{children}</>;
  }

  return (
    <Link
      href={`/user/${userId}`}
      className={css({
        _focus: {
          outline: 0,
        },
      })}
    >
      {children}
    </Link>
  );
};
