import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from '@app/_components/link';
import { css } from '@styled-system/css';
import { HStack, Box, Image } from '@styled-system/jsx';

type Props = {
  icon?: string;
  title: string;
  seeAllLink?: string;
};

export const SectionTitle = ({ icon, title, seeAllLink }: Props) => (
  <HStack width="100%">
    {icon && <Image src={icon} alt={title} marginTop="-3px" />}
    <h2
      className={css({
        fontSize: { base: 'size_16', lg: 'size_20' },
        fontWeight: '600',
        marginRight: '5px',
      })}
    >
      {title}
    </h2>
    <Box
      borderBottom={{ base: 'none', lg: '2px solid token(colors.separator)' }}
      flexGrow="1"
    />
    {seeAllLink && (
      <Link
        href={seeAllLink}
        className={css({
          marginLeft: '5px',
          color: 'tint',
          fontWeight: 'bold',
          alignItems: 'center',
          display: 'flex',
          _hover: { opacity: 0.7 },
          fontSize: { base: 'size_12', lg: 'size_14' },
        })}
      >
        もっと見る
        <MdKeyboardArrowRight size="20px" />
      </Link>
    )}
  </HStack>
);
