'use client';
import { Dialog } from '@ark-ui/react';
import type { FC } from 'react';
import { useCallback } from 'react';
import { css } from '@styled-system/css';
import { styled } from '@styled-system/jsx';

interface Props {
  open: boolean;
  onClose?(): void;
  children: React.ReactNode;
}

export const Actionsheet: FC<Props> = ({ open, onClose, children }) => {
  const handleOnOpenChange = useCallback(
    ({ open }: { open: boolean }) => {
      if (!open) {
        onClose?.();
      }
    },
    [onClose]
  );

  return (
    <Dialog.Root
      open={open}
      lazyMount
      unmountOnExit
      onOpenChange={handleOnOpenChange}
    >
      <Backdrop />
      <Positioner>
        <Content>{children}</Content>
      </Positioner>
    </Dialog.Root>
  );
};

const Content = styled(Dialog.Content, {
  base: {
    borderTopRadius: '10px',
    background: 'white',
    maxW: '990px',
    m: {
      base: '0',
      lg: 'auto',
    },
    boxShadow: 'lg',
    width: '100%',
    height: '100%',
    _open: {
      animation: 'slide-in-bottom 400ms cubic-bezier(0.05, 0.7, 0.1, 1.0)',
    },
    _closed: {
      animation: 'slide-out-bottom 150ms ease-out',
    },
  },
});

const Backdrop = styled(Dialog.Backdrop, {
  base: {
    backdropFilter: 'blur(4px)',
    background: 'rgba(0, 0, 0, 0.5)',
    height: '100vh',
    left: '0',
    position: 'fixed',
    top: '0',
    width: '100vw',
    zIndex: 'actionsheet_backgrop',
    _open: {
      animation: 'fadein 250ms ease-out',
    },
    _closed: {
      animation: 'fadeout 200ms ease-out',
    },
  },
});

const Positioner = styled(Dialog.Positioner, {
  base: {
    alignItems: 'center',
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    justifyContent: 'center',
    zIndex: 'actionsheet',
  },
});

export const actionsheetOptionStyle = css({
  display: 'flex',
  height: '45px',
  width: '100%',
  borderBottom: '1px solid #d7d8da',
  _lastOfType: {
    borderBottom: 'none',
    paddingBottom: 0,
  },
  _hover: {
    background: 'accent_50',
  },
  _focus: {
    outline: 0,
  },
});

export const actionsheetOptionLinkStyle = css({
  display: 'flex',
  justifyContent: 'left',
  gap: '8px',
  borderRadius: '6px',
  padding: '5px 15px',
  cursor: 'pointer',
  lineHeight: '36px',
  alignItems: 'center',
  color: 'accent.1000',
  width: '100%',
  _hover: {
    background: '#ebedf1',
  },
  _focus: {
    outline: 0,
  },
});
