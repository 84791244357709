import { flattenDeep } from 'lodash';
import { useEffect, useCallback, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import { useDetectBackForward } from '~/app/_hooks/use-detect-back-forward/use-detect-back-forward';
import type { MeRecommendedSeriesList } from '~/usecases/me-use-case';
import { fetchMeRecommendedSeries } from '~/usecases/me-use-case';
import {
  clearStoredSeries,
  getNumberOfStoredPagesFromSession,
  getStoredSeriesFromSession,
  storeSeriesInSession,
} from './store-series-in-session';
import { useKeepScrollPosition } from './use-keep-scroll-position';

export const useFetchMeRecommendedSeries = (uid?: string, limit?: number) => {
  const { isBackForward } = useDetectBackForward();

  const { restoreScrollPosition } = useKeepScrollPosition();

  const [restoreScrollOnLoad, setRestoreScrollOnLoad] = useState(false);

  const getKey = useCallback(
    (pageIndex, prevData) => {
      if (typeof isBackForward === 'undefined') {
        return null;
      }

      if (!uid) {
        return null;
      }
      if (prevData && !prevData.hasNextPage) {
        return null;
      }

      return ['/api/home-me/recommended', uid, pageIndex, limit];
    },
    [limit, uid, isBackForward]
  );

  const fetcher = useCallback(
    async ([_key, _uId, pageIndex, limit]: [
      string,
      string,
      number,
      number
    ]): Promise<MeRecommendedSeriesList | undefined> => {
      if (!uid) return;
      if (pageIndex === 0 && !isBackForward) {
        clearStoredSeries();
      }

      const storedSeries = getStoredSeriesFromSession(uid, pageIndex);

      if (isBackForward && storedSeries && storedSeries.seriesList.length > 0) {
        return storedSeries;
      }

      const serverResults = await fetchMeRecommendedSeries(pageIndex, limit);
      storeSeriesInSession(uid, serverResults);
      return serverResults;
    },
    [isBackForward, uid]
  );

  const {
    data: seriesPages,
    size,
    setSize,
    isValidating,
    isLoading,
  } = useSWRInfinite(getKey, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateFirstPage: false,
  });

  // When coming back, show the same number of pages as before
  useEffect(() => {
    if (!window || !uid || !isBackForward) return;

    const storedPages = getNumberOfStoredPagesFromSession(uid);
    if (storedPages && storedPages > 1) {
      setSize(storedPages);
      setRestoreScrollOnLoad(true);
    }
  }, [isBackForward, setSize, uid]);

  // Wait for data to be loaded before restoring scroll position
  useEffect(() => {
    if (!window || !uid || !isBackForward) return;
    if (restoreScrollOnLoad) {
      const storedPages = getNumberOfStoredPagesFromSession(uid);
      if (
        storedPages &&
        storedPages > 1 &&
        size === storedPages &&
        !isLoading
      ) {
        setTimeout(() => {
          restoreScrollPosition();
          setRestoreScrollOnLoad(false);
        }, 500);
      }
    }
  }, [
    isBackForward,
    isLoading,
    restoreScrollOnLoad,
    restoreScrollPosition,
    size,
    uid,
  ]);

  const loadNextPage = useCallback(async () => {
    if (!isValidating) {
      const _loadNextPage = async (nextSize: number) => {
        const result = await setSize(nextSize);
        const totalItemSize = flattenDeep(
          result?.map((item) => item?.seriesList || [])
        ).length;
        const hasNextPage = result?.[nextSize - 1]?.hasNextPage;

        // If the total number of items is 0 and there is a next page, load the next page
        if (totalItemSize === 0 && hasNextPage) {
          await _loadNextPage(nextSize + 1);
        }
      };

      await _loadNextPage(size + 1);
    }
  }, [isValidating, setSize, size]);

  return {
    seriesList:
      seriesPages?.map((seriesPage) => seriesPage?.seriesList).flat() ?? [],
    hasNextPage: seriesPages?.[size - 1]?.hasNextPage,
    loadNextPage,
    isLoading: isValidating || isLoading,
  };
};
