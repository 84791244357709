import { routes } from '~/utils/routes';
import { Link } from '@app/_components/link';
import { css } from '@styled-system/css';
import { Image, VStack, HStack, Paragraph } from '@styled-system/jsx';
import communityGuidelinesIcon from './assets/community_guidelines_icon.svg';

export const CommunityGuidelinesBanner = () => (
  <VStack
    borderRadius="8px"
    width={{ base: '100%', lg: '272px' }}
    maxWidth={{ base: '400px', lg: '272px' }}
    backgroundColor="white"
    backgroundPosition="center"
    backgroundSize="cover"
    padding="16px 16px 16px 16px"
    gap="12px"
    border={{ base: '1px solid token(colors.separator)', lg: 'none' }}
  >
    <HStack justifyContent="left" marginRight="auto" gap="8px">
      <Image
        src={communityGuidelinesIcon}
        alt="コミュニティガイドライン"
        marginRight="auto"
      />
      <Paragraph fontSize="size_16" fontWeight="700">
        コミュニティガイドライン
      </Paragraph>
    </HStack>

    <Paragraph fontSize="size_14" lineHeight="21px">
      すべての方に安全で快適な場所を提供するため、コミュニティガイドラインをご確認下さい。
    </Paragraph>
    <Link
      href={routes.handbook['community-guideline'].url()}
      className={css({
        color: 'tint',
        marginRight: 'auto',
        fontSize: 'size_14',
        _hover: {
          opacity: 0.7,
        },
      })}
    >
      詳しくはこちら
    </Link>
  </VStack>
);
