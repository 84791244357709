import { Box } from '@styled-system/jsx';

export const SpContentSeparator = () => (
  <Box
    height="8px"
    width="100%"
    backgroundColor="strong_bg"
    display={{ base: 'block', lg: 'none' }}
  />
);
