import { useCallback, useState } from 'react';
import useSWR from 'swr';
import { useDisclosure } from '~/app/_hooks/use-disclosure';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import type { SeriesOverview } from '~/common/types/series-overview';
import { routes } from '~/utils/routes';
import { logError } from '~/utils/utils';
import { fetchStoriesReadingProgressForHome } from '@app/(default-layout)/_home/_request/fetch-stories-reading-progress-for-home';
import { deleteSeriesReadingProgress } from '@app/_repositories/mutation-service';

const HOME_CONTINUE_READING_LIMIT = 10;

export const useLocalHooks = () => {
  const { session } = useTellerSession();
  const { data, mutate } = useSWR(
    session?.uid
      ? ['/me/home-stories-reading-progress-list', session?.uid]
      : null,
    () =>
      fetchStoriesReadingProgressForHome({
        limit: HOME_CONTINUE_READING_LIMIT,
      }),
    {
      revalidateOnFocus: true,
      revalidateOnMount: true,
    }
  );

  const [selectedSeries, setSelectedSeries] = useState<
    SeriesOverview | undefined
  >();
  const actionsheetModalDisclosure = useDisclosure();

  const openActionsheet = useCallback(
    (series: SeriesOverview) => {
      setSelectedSeries(series);
      actionsheetModalDisclosure.onOpen();
    },
    [actionsheetModalDisclosure]
  );

  const closeActionsheet = useCallback(() => {
    actionsheetModalDisclosure.onClose();
  }, [actionsheetModalDisclosure]);

  const goToSeries = useCallback(() => {
    if (typeof window === 'undefined' || !selectedSeries?.id) return;
    window.location.href = routes.series.url(selectedSeries.id);
  }, [selectedSeries?.id]);

  const deleteSeries = useCallback(async () => {
    if (!selectedSeries?.id) return;

    try {
      closeActionsheet();
      const newData = data?.filter((se) => se.id !== selectedSeries.id);

      mutate(
        deleteSeriesReadingProgress(selectedSeries.id).then(() => newData),
        {
          optimisticData: newData,
        }
      );
    } catch (error: any) {
      logError(error);
    }
  }, [closeActionsheet, data, mutate, selectedSeries?.id]);

  const [shareActionsheetOpen, setShareActionsheetOpen] = useState(false);

  const closeShareSeriesActionsheet = useCallback(
    () => setShareActionsheetOpen(false),
    []
  );

  const openShareSeriesActionsheet = useCallback(() => {
    closeActionsheet();
    setShareActionsheetOpen(true);
  }, [closeActionsheet]);

  const onClickSeriesOption = useCallback(
    (series: SeriesOverview) => {
      openActionsheet(series);
    },
    [openActionsheet]
  );

  return {
    storyList: data,
    onClickSeriesOption,
    selectedSeries,
    isActionsheetOpen: actionsheetModalDisclosure.isOpen,
    closeActionsheet,
    goToSeries,
    deleteSeries,
    shareActionsheetOpen,
    openShareSeriesActionsheet,
    closeShareSeriesActionsheet,
    showSensitive: session?.tellerUser?.showSensitive ?? false,
  };
};
