import { StoryAvailability } from '~/common/types/story-availability';
import { query_types as QueryTypes } from '~/infra/api/rpc';

export const convertIStoryAvailabilityToStoryAvailability = (
  storyAvailability?: QueryTypes.StoryAvailability
): StoryAvailability => {
  if (!storyAvailability) {
    return StoryAvailability.PUBLIC;
  }
  switch (storyAvailability) {
    case QueryTypes.StoryAvailability.STORY_AVAILABILITY_REQUIRE_FOLLOWED:
      return StoryAvailability.REQUIRE_AUTHOR_FOLLOW_USER;
    case QueryTypes.StoryAvailability.STORY_AVAILABILITY_REQUIRE_FOLLOWING:
      return StoryAvailability.REQUIRE_USER_FOLLOW_AUTHOR;
    case QueryTypes.StoryAvailability
      .STORY_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING:
      return StoryAvailability.REQUIRE_MUTUAL_FOLLOW;
    case QueryTypes.StoryAvailability.STORY_AVAILABILITY_REQUIRE_VIP:
      return StoryAvailability.REQUIRE_VIP;
    case QueryTypes.StoryAvailability.STORY_AVAILABILITY_REQUIRE_WAIT:
      return StoryAvailability.REQUIRE_WAIT;
    default:
      return StoryAvailability.PUBLIC;
  }
};
