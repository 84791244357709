import { useCallback, useMemo } from 'react';
import { useDeepLinkFromPath } from '~/app/_hooks/use-deep-link-from-path/use-deep-link-from-path';
import {
  buildOpenAppClickedParams,
  sendToFirebaseAnalytics,
} from '~/common/hooks/use-firebase-analytics';
import { createAppInstallUrl } from '~/utils/url';

export const useLocalState = () => {
  const { deepLinkUrl } = useDeepLinkFromPath();

  const link = useMemo(() => {
    return createAppInstallUrl({
      deepLinkValue: deepLinkUrl,
    });
  }, [deepLinkUrl]);

  const handleOnClickOpenInApp = useCallback(() => {
    if (typeof window === 'undefined') return;

    const eventPage = location.pathname;
    const eventParentPage = location.pathname.replace(/\/[^/]*$/, '');
    sendToFirebaseAnalytics(
      'open_app_clicked',
      buildOpenAppClickedParams({
        page: eventPage,
        parentPage: eventParentPage,
        component: 'footer_banner',
      })
    );
  }, []);

  return {
    handleOnClickOpenInApp,
    link,
  };
};
