import { atom, useAtomValue, useSetAtom } from 'jotai';

// Showing Google One Tap Login Prompt
export const showingGoogleOneTapLoginPromptAtom = atom(false);

export const useSetShowingGoogleOneTapLoginPrompt = () => {
  const setShowingGoogleOneTapLoginPrompt = useSetAtom(
    showingGoogleOneTapLoginPromptAtom
  );

  return {
    setShowingGoogleOneTapLoginPrompt,
  };
};

export const useGetShowingGoogleOneTapLoginPrompt = () => {
  const showingGoogleOneTapLoginPrompt = useAtomValue(
    showingGoogleOneTapLoginPromptAtom
  );

  return {
    showingGoogleOneTapLoginPrompt,
  };
};
