'use client';
import { InstallAppBanner } from '~/app/_components/install-app-banner';
import { useLocalState } from './use-local-state';

export const InstallAppBannerContainer = () => {
  const { handleOnClickOpenInApp, link } = useLocalState();
  return (
    <InstallAppBanner
      display={{
        base: 'none',
        lgDown: 'block',
      }}
      width="100%"
      openAppClick={handleOnClickOpenInApp}
      link={link}
    />
  );
};
