import { convertISeriesResponseToSeriesOverview } from '~/common/converter/series/series';
import { convertISharedWithToSeriesAvailability } from '~/common/converter/series/series-availability';
import { convertIUserResponseToUserOverview } from '~/common/converter/user/user';
import { StoryAvailability } from '~/common/types/story-availability';
import { StoryOverview, StoryType } from '~/common/types/story-overview';
import { StoryScriptType, UserRole } from '~/models/query-types';
import type { StoryResponse } from '~/repositories/query-repository';
import { formatDateJapanese } from '~/utils/form';
import { convertIStoryAvailabilityToStoryAvailability } from './story-availability';

export const convertIStoryResponseToStoryOverview = (
  story: StoryResponse,
  onlyStory = false,
  useStoryInformation = false
): StoryOverview => {
  const id = story.id!;
  const isSeries = onlyStory ? false : !!story.series;
  const storyTitle = story?.title ?? '';
  const description = story?.series?.description ?? '';

  // シリーズ情報
  const series =
    !onlyStory && story.series
      ? convertISeriesResponseToSeriesOverview(story.series)
      : null;
  const seriesAvailability = convertISharedWithToSeriesAvailability(
    story.series?.shared_with_status
  );
  const seriesIndex = story.series_index ?? null;
  const tags = (story?.series?.tags ?? []).map((tag) => tag!);
  const publishedAt = new Date(story.published_at ?? 0);
  const publishedAtText = formatDateJapanese(publishedAt);
  const updatedAt = new Date(story.updated_at ?? 0);

  const storyAvailability = convertIStoryAvailabilityToStoryAvailability(
    story.availability
  );

  const isWaitRequired =
    !useStoryInformation && isSeries && story?.series !== null
      ? story.series?.has_free_story === false
      : storyAvailability === StoryAvailability.REQUIRE_WAIT;
  const isVipOnly =
    storyAvailability === StoryAvailability.REQUIRE_VIP &&
    (!isSeries || useStoryInformation || onlyStory);
  const isSensitive =
    (story.is_admin_sensitive || story.is_user_sensitive) ?? false;
  const isOfficial = story.is_official || false;
  const isNovel =
    story?.script_type === StoryScriptType.STORY_SCRIPT_TYPE_NOVEL;
  const likeCount = story.like_count || 0;
  const storyType =
    story.script_type === StoryScriptType.STORY_SCRIPT_TYPE_CHAT_NOVEL
      ? StoryType.CHAT
      : StoryType.NOVEL;

  return {
    id,
    storyTitle,
    description,
    tags,
    isSeries,
    series,
    seriesAvailability,
    seriesIndex,
    publishedAtISOString: publishedAt.toISOString(),
    publishedAtText,
    updatedAtISOString: updatedAt.toISOString(),
    isWaitRequired,
    isVipOnly,
    isSensitive,
    isOfficial,
    isNovel,
    likeCount,
    user:
      series?.user ??
      (story.user ? convertIUserResponseToUserOverview(story.user) : null),
    storyType,
    availability: storyAvailability,
  };
};

/**
 * QueryServiceから取得したストーリーのリストをStoryの配列に変換するConverter
 * @param response
 * @returns
 */
export const convertQueryResponseToStories = (
  response: StoryResponse[],
  onlyStory = false
): StoryOverview[] => {
  return response
    .filter((s) => {
      // ストーリーに紐づくユーザがあり、ユーザがBANされていたらリストに表示しない
      if (s.user?.user_role === UserRole.USER_ROLE_BANNED) {
        return false;
      }

      return true;
    })
    .map((s) => convertIStoryResponseToStoryOverview(s, onlyStory));
};
