import { useState, useEffect } from 'react';

export const useDetectBackForward = () => {
  const [isBackForward, setIsBackForward] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (!window) return;

    const isNavigateType =
      // @ts-ignore
      window.performance.getEntriesByType('navigation')[0]?.type;

    setIsBackForward(isNavigateType === 'back_forward');
  }, []);

  return {
    isBackForward,
  };
};
