import { useBeforeUnload } from 'react-use';
import { useScrollPosition } from '~/common/hooks/use-scroll-position';
const TOP_SCROLL_POSITTION_KEY = 'top-scroll-position';

const storeScrollPosition = (scrollPosition: number) => {
  if (!window) return;
  sessionStorage.setItem(TOP_SCROLL_POSITTION_KEY, scrollPosition.toString());
};

const getStoredScrollPosition = () => {
  if (!window) return;
  const storedPosition = sessionStorage.getItem(TOP_SCROLL_POSITTION_KEY);
  if (storedPosition) {
    return Number.parseInt(storedPosition, 10);
  }
  return;
};

const restoreScrollPosition = () => {
  const storedPosition = getStoredScrollPosition();
  if (storedPosition) {
    window.scrollTo(0, storedPosition);
  }
};

export const useKeepScrollPosition = () => {
  const { scrollPosition } = useScrollPosition();

  useBeforeUnload(() => {
    storeScrollPosition(scrollPosition);
    return false;
  });

  return {
    restoreScrollPosition,
  };
};
