import { Portal } from '@ark-ui/react';
import type { FC } from 'react';
import { FiInfo, FiShare, FiSlash } from 'react-icons/fi';

import {
  Actionsheet,
  actionsheetOptionLinkStyle,
} from '@app/_components/actionsheet';
import { VStack, HStack, Paragraph } from '@styled-system/jsx';

interface Props {
  title?: string;
  onGoToSeriesClick: () => void;
  onDeleteClick: () => void;
  onShareClick: () => void;
  isOpen: boolean;
  close: () => void;
}

export const BookshelfSeriesActionsheet: FC<Props> = ({
  title,
  onGoToSeriesClick,
  onDeleteClick,
  onShareClick,
  isOpen,
  close,
}) => {
  return (
    <>
      <Portal>
        <Actionsheet open={isOpen} onClose={close}>
          <VStack position="relative" zIndex="share_popover" gap="12px">
            <VStack
              minH="150px"
              bg="white"
              minW="100%"
              borderTopLeftRadius="8px"
              borderTopRightRadius="8px"
              justifyContent="flex-start"
              alignItems="center"
              textAlign="center"
              padding="8px 0"
              gap="10px"
            >
              <Paragraph
                fontSize={{ base: 'size_16', lg: 'size_18' }}
                padding="8px 16px"
                fontWeight="700"
                width="100%"
                borderBottom="1px solid token(colors.separator)"
                maxLines="1"
                lineClamp="1"
                lineHeight="38px"
              >
                {title}
              </Paragraph>
              <VStack
                w="100%"
                gap="8px"
                padding="16px"
                fontSize={{ base: 'size_14', lg: 'size_!6' }}
                fontWeight="700"
              >
                <HStack
                  w="100%"
                  onClick={onGoToSeriesClick}
                  className={actionsheetOptionLinkStyle}
                >
                  <FiInfo size="20px" />
                  <Paragraph>作品詳細を表示</Paragraph>
                </HStack>
                <HStack
                  w="100%"
                  onClick={onDeleteClick}
                  className={actionsheetOptionLinkStyle}
                >
                  <FiSlash size="20px" />
                  <Paragraph>履歴から削除</Paragraph>
                </HStack>
                <HStack
                  w="100%"
                  alignContent="center"
                  onClick={onShareClick}
                  className={actionsheetOptionLinkStyle}
                >
                  <FiShare size="20px" />
                  <Paragraph>共有</Paragraph>
                </HStack>
              </VStack>
            </VStack>
          </VStack>
        </Actionsheet>
      </Portal>
    </>
  );
};
