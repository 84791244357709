export const StoryAvailability = {
  PUBLIC: 'PUBLIC',
  REQUIRE_AUTHOR_FOLLOW_USER: 'REQUIRE_AUTHOR_FOLLOW_USER',
  REQUIRE_USER_FOLLOW_AUTHOR: 'REQUIRE_USER_FOLLOW_AUTOR',
  REQUIRE_MUTUAL_FOLLOW: 'REQUIRE_MUTUAL_FOLLOW',
  REQUIRE_VIP: 'REQUIRE_VIP',
  REQUIRE_WAIT: 'REQUIRE_WAIT',
} as const;
export type StoryAvailability =
  typeof StoryAvailability[keyof typeof StoryAvailability];
