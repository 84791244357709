import { useCallback, useState, RefObject, useMemo } from 'react';
import { useSystemNotification } from '@app/_hooks/use-system-notification';

export const useLocalHooks = ({
  text,
  url,
  hashtags,
  urlInputRef,
  onShare,
  onClose,
}: {
  text: string;
  url: string;
  hashtags?: ReadonlyArray<string>;
  urlInputRef: RefObject<HTMLInputElement>;
  onShare?: () => void;
  onClose?: () => void;
}) => {
  const encodedUrl = useMemo(() => {
    return encodeURIComponent(url);
  }, [url]);

  const encodedText = useMemo(() => {
    return encodeURIComponent(text);
  }, [text]);

  const encodedTags = useMemo(() => {
    if (!hashtags) return '';

    return encodeURIComponent(hashtags.join(', '));
  }, [hashtags]);

  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => {
    if (isOpen) return;
    setOpen(true);
  }, [isOpen]);
  const close = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose]);

  const { notify } = useSystemNotification();

  const copyLink = useCallback(() => {
    const input = urlInputRef.current;
    if (input && typeof document !== 'undefined') {
      input.select();
      document.execCommand('copy');
      notify('URLをコピーしました', 'info');
      close();
      onShare?.();
    }
  }, [urlInputRef, notify, close, onShare]);

  const webShare = useCallback(async () => {
    if (!navigator.share) {
      notify('共有に失敗しました', 'error');
      onShare?.();
      close();
      return;
    }

    try {
      await navigator.share({
        text: text,
        url: url,
      });
    } catch {
      // nop
    } finally {
      onShare?.();
      close();
    }
  }, [close, notify, onShare, text, url]);

  return {
    encodedText,
    encodedUrl,
    encodedTags,
    copyLink,
    webShare,
    isOpen,
    open,
    close,
  };
};
