'use client';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { useState, useEffect } from 'react';
import type { PropsWithChildren } from 'react';
import { Box, HStack } from '@styled-system/jsx';

type Props = PropsWithChildren<{}>;

export const HomeSeriesCarousel = ({ children }: Props) => {
  if (!Array.isArray(children)) {
    throw new TypeError('children should be Array');
  }

  const [loaded, setIsLoaded] = useState(false);

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
    },
    [
      Autoplay({
        playOnInit: true,
        stopOnMouseEnter: true,
        stopOnInteraction: false,
        delay: 4500,
      }),
    ]
  );

  emblaApi?.on('init', () => {
    setIsLoaded(true);
  });

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        emblaApi?.scrollNext();
      }, 1500);
    }
  }, [emblaApi, loaded]);

  return (
    <Box
      width="100%"
      overflow="hidden"
      padding={{ base: '8px 8px 0 8px', lg: '0' }}
      ref={emblaRef}
    >
      <HStack>
        {Array.isArray(children)
          ? children.map((child, index) => (
              <Box
                key={`carousel-${index}`}
                opacity={loaded ? 1 : 0.2}
                flexGrow={0}
                flexShrink={0}
                flexBasis={{
                  base: '90%',
                  sm: '50%',
                  md: '60%',
                  lg: '35%',
                  xl: '30%',
                  '3xl': '19%',
                }}
                minW="0"
                _lastOfType={{
                  marginRight: '10px',
                }}
              >
                {child}
              </Box>
            ))
          : null}
      </HStack>
    </Box>
  );
};
