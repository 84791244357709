'use client';
import { AnimatePresence, motion } from 'framer-motion';
import { HorizontalArrowsCarousel } from '~/app/(default-layout)/_home/_components/horizontal-arrows-carousel';
import { BookshelfSeriesActionsheet } from '~/app/(default-layout)/bookshelf/_components/bookshelf-series-actionsheet';
import { Env } from '~/utils/environments';
import { routes } from '~/utils/routes';
import { ContinueReadingSeriesItem } from '@app/(default-layout)/_home/_components/continue-reading-series-item';
import { ContinueReadingSeriesItemSkeleton } from '@app/(default-layout)/_home/_components/continue-reading-series-item/continue-reading-series-item-skeleton';
import { SectionTitle } from '@app/(default-layout)/_home/_components/section-title';
import { ShareActionsheet } from '@app/_components/share-actionsheet';
import { VStack, HStack } from '@styled-system/jsx';
import { useLocalHooks } from './hooks';

export const ContinueReadingContainer = () => {
  const {
    storyList,
    selectedSeries,
    onClickSeriesOption,
    isActionsheetOpen,
    goToSeries,
    deleteSeries,
    shareActionsheetOpen,
    openShareSeriesActionsheet,
    closeShareSeriesActionsheet,
    closeActionsheet,
    showSensitive,
  } = useLocalHooks();

  const skeletons = [1, 2, 3, 4, 5];

  if (storyList?.length === 0) return null;

  return (
    <>
      <VStack
        marginTop={{ base: '0', lg: '40px' }}
        width="100%"
        padding={{ base: '0 16px', lg: '8px' }}
      >
        <SectionTitle title="続きから読む" />

        {!storyList && (
          <HStack alignItems="baseline" gap="16px" width="100%">
            {skeletons.map((sk) => (
              <ContinueReadingSeriesItemSkeleton key={sk} />
            ))}
          </HStack>
        )}
        {storyList && (
          <HorizontalArrowsCarousel
            gap="16px"
            height={storyList ? '120px' : '140px'}
            alignItems="baseline"
            maxWidth="100%"
            width="100%"
            carouselStyle="smallCell"
          >
            <AnimatePresence initial={false}>
              {storyList?.map((s) => (
                <motion.div
                  key={`home-reading-${s.id}`}
                  initial={{ opacity: 0, translateY: 10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    delay: 0.2,
                    duration: 0.3,
                    ease: 'easeOut',
                  }}
                >
                  <ContinueReadingSeriesItem
                    story={s}
                    onClickOptions={onClickSeriesOption}
                    showSensitive={showSensitive}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </HorizontalArrowsCarousel>
        )}
      </VStack>
      <BookshelfSeriesActionsheet
        title={selectedSeries?.title}
        isOpen={isActionsheetOpen}
        onGoToSeriesClick={goToSeries}
        onDeleteClick={deleteSeries}
        onShareClick={openShareSeriesActionsheet}
        close={closeActionsheet}
      />

      {selectedSeries && (
        <ShareActionsheet
          isOpen={shareActionsheetOpen}
          onClose={closeShareSeriesActionsheet}
          text={`${selectedSeries.title} by ${selectedSeries.user?.name}`}
          url={`${Env.baseURL}${routes.series.url(selectedSeries.id)}`}
          hashtags={selectedSeries.tags}
          onShare={closeShareSeriesActionsheet}
        />
      )}
    </>
  );
};
