import { AppError, ErrorType } from '~/models/app-error';

const BASE_URL = `${process.env['NEXT_PUBLIC_API_ENDPOINT']}/twirp`;

export type FetchParams = {
  path: string;
  options?: RequestInit;
};

export const fetchClient = async <T>({
  path,
  options,
}: FetchParams): Promise<T> => {
  const headers = new Headers(options?.headers || {});
  headers.set('Content-Type', 'application/json');

  const response = await fetch(`${BASE_URL}${path}`, { ...options, headers });

  // Error handling
  if (!response.ok) {
    // エラーハンドリングのロジックをここに記述
    // 例: if (response.status === 409) { ... }
    if (response.status === 409) {
      throw new AppError(ErrorType.NETWORK_ERROR, 'ネットワークエラーです');
    }
    if (response.status === 403) {
      throw new AppError(
        ErrorType.API_REQUEST_403,
        'この操作に対する権限がありません',
        response.status,
        response
      );
    }
    if (response.status >= 400 && response.status < 500) {
      throw new AppError(
        ErrorType.API_REQUEST_404,
        '',
        response.status,
        response
      );
    }

    if (response.status >= 500 && response.status < 600) {
      throw new AppError(
        ErrorType.API_REQUEST_500,
        '',
        response.status,
        response
      );
    }

    throw new AppError(ErrorType.UNKNOWN_ERROR, '', response.status, response);
  }

  if (response.status === 204) return {} as T;

  return response.json();
};
