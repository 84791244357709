import { Button } from '~/app/_components/button';
import { Image, VStack, Paragraph } from '@styled-system/jsx';
import letsWriteBg from './assets/lets_write_bg.png';
import letsWriteTitle from './assets/lets_write_title.svg';
import tenoGirl from './assets/teno_girl.png';

type Props = {
  onClickWrite: () => void;
};

export const LetsWriteBanner = ({ onClickWrite }: Props) => (
  <VStack
    borderRadius="8px"
    width={{ base: '100%', lg: '306px' }}
    maxWidth={{ base: '400px', lg: '306px' }}
    height={{ base: 'auto', lg: '424px' }}
    backgroundColor="cute_blue"
    style={{ backgroundImage: `url('${letsWriteBg}')` }}
    backgroundPosition="center"
    backgroundSize="cover"
    padding={{ base: '16px', lg: '16px 24px 0' }}
  >
    <Image
      marginTop={{ base: '0', lg: '8px' }}
      marginRight="auto"
      src={letsWriteTitle}
      alt="小説を書こう"
    />

    <Paragraph
      marginTop={{ base: '0', lg: '8px' }}
      fontSize={{ base: 'size_14', lg: 'size_16' }}
      lineHeight="26px"
      color="white"
    >
      テラーノベルは書いたら必ず読まれる小説共有プラットフォームです。あなただけのストーリーを書いてたくさんファンをつくろう！
    </Paragraph>

    <Image
      width={{ base: '150px', lg: '257px' }}
      marginTop={{ base: '0', lg: '20px' }}
      src={tenoGirl}
      alt="小説を書こう"
    />

    <Button
      size="small"
      width="100%"
      maxWidth={{ base: '100%', lg: '258px' }}
      height="50px"
      backgroundColor="tint"
      color="white"
      fontSize={{ base: 'size_16', lg: 'size_14' }}
      border="2px solid white"
      marginTop="-11px"
      marginBottom={{ base: '0', lg: '16px' }}
      onClick={onClickWrite}
      _focus={{
        outline: 0,
      }}
    >
      ストーリーを書く
    </Button>
  </VStack>
);
