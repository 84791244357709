import { memo } from 'react';
import { NameConstants } from '~/utils/constants';
import { Link } from '@app/_components/link';
import { AspectRatio, Image, VStack, VstackProps } from '@styled-system/jsx';
import appBanner from './assets/app-banner.png';

type Props = {
  openAppClick?: () => void;
  link: string;
} & VstackProps;

const _InstallAppBanner = ({ openAppClick, link, ...vstackProps }: Props) => (
  <VStack {...vstackProps}>
    <Link href={link} onClick={openAppClick}>
      <AspectRatio ratio={375 / 398} w="100%">
        <Image
          src={appBanner}
          alt={`チャット小説は${NameConstants.APP_NAME}アプリをインストール`}
          loading="lazy"
          htmlWidth="100%"
        />
      </AspectRatio>
    </Link>
  </VStack>
);

export const InstallAppBanner = memo(_InstallAppBanner);
