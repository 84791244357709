import type { FC } from 'react';
import { css } from '@styled-system/css';

type Props = {
  label: string;
  width?: number | string;
  height?: number | string;
};

export const Badge: FC<Props> = ({ label, width, height }) => (
  <span
    className={css({
      position: 'absolute',
      top: '4px',
      right: '4px',
      fontSize: 'size_10',
      fontWeight: 700,
      color: 'primary',
      padding: '2px 6px',
      lineHeight: '15px',
      background: 'rgba(102, 102, 102, 0.5)',
      border: '0.5px solid #d7d8da',
      backdropFilter: 'blur(1px)',
      borderRadius: '20px',
      width: width ?? '35px',
      height: height ?? '19px',
    })}
  >
    {label}
  </span>
);
