import { getFirebaseUser } from '~/lib/firebase';
import { AppError, ErrorType } from '~/models/app-error';
import { isServer } from '~/utils/ssr';
import { fetchClient, FetchParams } from './fetch';

export const fetchForClient = async <T>({
  path,
  options,
}: FetchParams): Promise<T> => {
  if (isServer()) {
    throw new AppError(
      ErrorType.UNKNOWN_ERROR,
      'This function is only available on the server side'
    );
  }

  const firebaseUser = await getFirebaseUser();
  const idToken = await firebaseUser?.getIdToken();

  const credentialHeaders = new Headers(options?.headers || {});
  credentialHeaders.set('Authorization', `Bearer ${idToken}`);

  return fetchClient({
    path: path,
    options: {
      ...options,
      headers: credentialHeaders,
    },
  });
};
