import { useEffect, useMemo, useState } from 'react';

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  const scrollPercentage = useMemo(() => {
    if (typeof document === 'undefined' || typeof window === 'undefined')
      return 0;

    const scrollHeight = document.body.scrollHeight - window.innerHeight;
    return scrollPosition / scrollHeight;
  }, [scrollPosition]);

  return {
    scrollPosition,
    scrollPercentage,
  };
};
