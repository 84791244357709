export const ImageCategory = {
  USER_PROFILE: 'userProfile',
  CHARACTER_ICON: 'characterIcon',
  PUSH_NOTIFICATION_ICON: 'pushNotificationIcon',
  CHAT_BACKGROUND: 'chatBackground',
  TALK_BACKGROUND: 'talkBackground',
  CONTENT: 'content',
  STORY_SERIES_THUMBNAIL: 'storySeriesThumbnail',
  STAMP: 'stamp',
} as const;
export type ImageCategory = typeof ImageCategory[keyof typeof ImageCategory];

export const ImageFormat = {
  PNG: 'png',
  JPEG: 'jpeg',
  GIF: 'gif',
  WEBP: 'webp',
} as const;
export type ImageFormat = typeof ImageFormat[keyof typeof ImageFormat];

export type Image = {
  id: string;
  servingUrl: string;
  format: ImageFormat | null;
  width: number | null;
  height: number | null;
  category: ImageCategory | null;
};
